import React, { useRef, useEffect } from 'react'

import { BoundingBoxPrediction } from '../../pages/tagging/BoundingBoxCanvas'

interface Props {
  src: string
  predictions: BoundingBoxPrediction[]
}

export default function ImageWithBoundingBoxes(props: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    const image = imageRef.current

    if (image && canvas && context) {
      image.onload = () => {
        const imgWidth = image.naturalWidth
        const imgHeight = image.naturalHeight

        // Calculate scaling factor to fit into 250x250
        const scale = Math.min(250 / imgWidth, 250 / imgHeight)

        // Set canvas dimensions to 250x250
        canvas.width = 250
        canvas.height = 250

        // Draw image scaled to fit 250x250
        context.drawImage(image, 0, 0, imgWidth * scale, imgHeight * scale)

        props.predictions.forEach(({ boundingBox }) => {
          context.strokeStyle = '#fe01a1'
          context.lineWidth = 2

          const startX = boundingBox.start.x * imgWidth * scale
          const startY = boundingBox.start.y * imgHeight * scale
          const endX = boundingBox.end.x * imgWidth * scale
          const endY = boundingBox.end.y * imgHeight * scale

          const boxWidth = endX - startX
          const boxHeight = endY - startY

          // Draw scaled bounding box
          context.strokeRect(startX, startY, boxWidth, boxHeight)
        })
      }
    }
  }, [props.src, props.predictions])

  return (
    <div className="image-container" style={{ position: 'relative', width: '250px', height: '250px' }}>
      <img ref={imageRef} src={props.src} alt="With Bounding Boxes" style={{ display: 'none' }} />
      <canvas ref={canvasRef} />

      {/* Overlay text props.predictions */}
      {props.predictions.map(({ boundingBox, prediction }, index) => {
        const imgWidth = imageRef.current?.naturalWidth || 1
        const imgHeight = imageRef.current?.naturalHeight || 1
        const scale = Math.min(250 / imgWidth, 250 / imgHeight)

        const startXPercent = boundingBox.start.x * imgWidth * scale
        const endYPercent = boundingBox.end.y * imgHeight * scale

        return (
          <div
            key={index}
            className="text-xxs position-absolute max-width-150px"
            style={{
              left: `${startXPercent}px`,
              top: `${endYPercent}px`,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              padding: '2px 4px',
            }}
          >
            {prediction?.map(([_, details]) => details).join(' / ')}
          </div>
        )
      })}
    </div>
  )
}
